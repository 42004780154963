




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.demo-ruleForm {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    min-width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 550px;
    .el-input {
      width: 280px;
      float: left;
    }
    .el-button {
      float: right;
    }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      width: 100px;
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
  .tb-edit .el-select {
    display: none;
  }

  .tb-edit .current-row .el-select {
    display: block;
  }

  .tb-edit .current-row .el-select + span {
    display: none;
  }
}
.demo-ruleForm /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
span.red,.red .el-input__inner{
  color: red;
}
